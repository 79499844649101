<template>
  <div v-if="templateCurrent !==null" class=" vx-row w-full  ml-auto mr-auto">
    <div class="vx-col w-1/2">
        <span>
        <h4 class="mb-5 ">Template LAF    <br> <vs-button v-if="imageload"
                                                          class="ml-auto mt-3 bg-primary"
                                                          size="small"
                                                          v-on:click="handleReplaceTemplate"
                                                          icon-pack="feather"
        >Change Template File
      </vs-button></h4>
      </span>
      <div class="row bg bg-light" style="padding-bottom: 60px;">
        <div class="col-md-10 offset-md-1">
          <div class="flex-container">
            <div class="flex-item">

              <div class="content">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                        ref="cropper"
                        :src="imgSrc"
                        preview=".preview"
                        :view-mode="1"
                        :auto-crop-area="0.5"
                        :ready="onCropperReady"
                        :cropend="handleCropMove"
                        :fixed="true"
                        :info="true"
                        :auto-crop="true"
                        :auto-crop-width="300"
                        :auto-crop-height="300"
                        :can-move="false"
                        :center-box="true"
                        :zoomable="false"
                        :rotatable="false"
                    />
                  </div>
                </section>
              </div>
              <div v-if="imageload">
                <div class="vx-col w-[80%]">
                  <div class="showBox p-2">
                    <vs-button class="float-right ml-2" @click.stop="getOcr"> OCR</vs-button>
                    <div class="preview"/>
                  </div>
                </div>
                <div class="vx-row mb-2 mt-6">
                  <div class="vx-col w-full"><b>Text OCR</b>
                    <vs-textarea
                        height="600px"
                        readonly=""
                        v-model="ocr_value"/>
                  </div>
                </div>
                <div class="vx-col w-[20%]">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-col w-1/2">
      <div v-if="templateCurrent !==null">
        <div class="w-full">
          <div class="w-full bold "> Document Pattern</div>
            <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input
                  class="w-full"
                  v-model="templateCurrent.all.pattern"
                  placeholder="text pattern"
              />

              <span
                  class="text-danger text-sm"
                  v-if="templateCurrent.all.pattern === '' && status_submit"
              >This field is required</span>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="w-full mx-2">
            <div   class="w-[0.9]  vx-col">
              <div class="scroll-container" v-if="optionImageThub">
                <img
                    class="m-1"
                    v-for="(src, index) in optionImageThub"
                    :key="index"
                    :src="src"
                    width="86px"
                    alt="page-1"
                    @click.stop="previewImage(index,'laf.header' )"
                    :class="{ selected: templateCurrent.laf.header.page_number === index }"
                />
                <span v-if="!optionImageThub" class="box-loading">Empty image..</span>
              </div>
            </div>

            <vs-divider style="width: 100%;  "></vs-divider>
            <div class="w-full">
              <div class="w-full bold "> Barcode LAF</div>
              <div class="vx-row mb-2">
                <div class="vx-col w-1/3">
                  <vs-input
                      class="w-full "
                      label="Start Text"
                      v-model="templateCurrent.laf.barcode_laf.start"
                      placeholder="start text pattern"
                  />
                </div>
                <div class="vx-col w-1/3 mb-2">
                  <vs-input
                      class="w-full "
                      label="Delimiter"
                      v-model="templateCurrent.laf.barcode_laf.delimiter"
                      placeholder="delimiter text"
                  />
                </div>
                <div class="vx-col w-1/3 mb-2">
                  <vs-input
                      class="w-full "
                      label="End Text"
                      v-model="templateCurrent.laf.barcode_laf.end"
                      placeholder="end text pattern"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <span>
      <h4 class="mb-5"></h4>
    </span>
      <div class="vx-row mb-2 mt-6 p-2" v-if="imageload">
        <div class="vx-col w-full">
            <vs-button class="mr-3 mb-2  " @click.stop="setTemplateDefaultValue">Load Default Setting</vs-button>

          <vs-button class="mr-3 mb-2" @click.stop="handleUpdate">Update
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {VueCutter} from 'vue-cutter'
import { Int} from "vuecode.js";
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    VueCutter,
    VueCropper
  },
  props: {
    dataItem: {
      type: [],
    },
    fileName: {
      type: String,
    },
    dataJSON: {
      type: Object
    },
    dataAll: {
      type: Object
    },
    page: {
      type: Int,
    },
    pageCount: {
      type: Int,
    },
    dataTemplate: {
      type: Object
    },
    tID: {
      type: Number,
    },
    ocr_var: {
      type: String,
    },
  },
  created() {
    this.selectedPages = [this.pageOptions[0]];
  },
  data() {
    return this.initialState();
  },
  methods: {
    getValueFromObject(objJson, keyVar) {
      if (objJson && typeof objJson !== 'undefined' && objJson !== "") {
        let jsonData = JSON.parse(objJson)
        return jsonData[keyVar]
      }
      return ""
    },
    formatCurrency(value) {
      return this.numberFormat(value)
    },
    numberFormat(number) {
      if (number == null || typeof number === "undefined") {
        return "0"
      }
      var strNumber = parseFloat(number.toString().replace(",", ".")).toFixed(2);
      var formatter = new Intl.NumberFormat('id-ID');
      return formatter.format(strNumber) === "NaN" ? "0" : formatter.format(strNumber).toString().replaceAll(".", ",") + ".00";

    },
    setFocusedInput(varName) {
      this.ocr_var = varName;
    },
    handleNumericInput(event, idx) {
      setTimeout(() => {
        this.dataTemplate[idx] = event.replace(/\D/g, "");
      }, 10);
    },
    previewImage(idx, reg_name) {
      this.optionCutter.src = this.optionImageThub[idx];
      this.imgSrc = this.optionImageThub[idx];


      this.$refs.cropper.replace(this.imgSrc);
      this.selectedImageIndex = idx;
      this.regName = reg_name;
      this.data = JSON.stringify(this.regions[this.regName]);
      if (reg_name === "laf.footer") {
        this.templateCurrent.laf.footer.page_number = idx
      } else if (reg_name === "laf.header") {
        this.templateCurrent.laf.header.page_number = idx
      }
      this.setData();
      this.setCropBoxData();

    },
    getOcr() {

      let varData = this.ocr_var
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          let image64 = reader.result.split(",")
          this.getTextOcr(image64[1], varData)

        };
      });

      this.getData();
    },
    getQR() {
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextQR(image64[1])
      })
    },
    handleSelectionChangePage(newValues) {
      this.getPdfS3Image(newValues.page);
    },
    customLabelPage(option) {
      return `Page ${option.page}`;
    },
    addDays(dateStr, days) {
      return moment(dateStr).add(days, 'days').format('YYYY-MM-DD');
    },
    setTemplateFields(templateType, fields) {
      fields.forEach(field => {
        this.templateCurrent[templateType][field.name] = {
          start: "",
          delimiter: "",
          end: "",
          ...(field.lines && {lines: field.lines}),
          ...(field.page_number && {page_number: field.page_number}),
          ...(field.region_types && {region_types: field.region_types})
        };
      });
    },
    setTemplateDefaultValue() {
      this.templateCurrent["all"]["pattern"] = "Pembayaran Untuk / Kepada";
      this.templateCurrent["laf"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "image",
        x: 0,
        y: 1471,
        width: 1200,
        height: 224,
      }


      this.templateCurrent["laf"]["barcode_laf"] = {
        start: "",
        delimiter: "ENTER",
        end: "ENTER",
      }

      const createRegion = (template, section) => ({
        x: template[section].x,
        y: template[section].y,
        width: template[section].width,
        height: template[section].height,
        scaleX: 1,
        scaleY: 1,
      });

      this.regions = {
        "laf.header": createRegion(this.templateCurrent.laf, "header"),
      };

      this.$forceUpdate()
    },
    setTemplateEmptyValue() {

      let emptyValue = {
        start: " ",
        delimiter: " ",
        end: " ",
      }

      this.templateCurrent["laf"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "full",
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }

      this.templateCurrent["laf"]["barcode_laf"] = emptyValue

      const createRegion = (template, section) => ({
        x: template[section].x,
        y: template[section].y,
        width: template[section].width,
        height: template[section].height,
        scaleX: 1,
        scaleY: 1,
      });

      this.regions = {
        "laf.header": createRegion(this.templateCurrent.laf, "header")
      };
      this.setTemplateDefaultValue()


      this.$forceUpdate()
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      let newLocation = this.$refs.cropper.getData()
      if (this.regName !== "") {
        this.regions[this.regName] = newLocation;
      }
      this.data = JSON.stringify(newLocation, null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      setTimeout(() => {
        this.$refs.cropper.setData(JSON.parse(this.data));
        this.$forceUpdate()
      }, 100)
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    initialState() {
      return {
        imgSrc: null,
        regName: "",
        cropImg: '',
        data: null,
        ocr_value: null,
        selectedRole: null,
        selected: null,
        selectedImageIndex: -1,
        selectedBank: null,
        selectedPages: [],
        pageOptions: Array.from({length: this.pageCount}, (_, index) => ({page: index + 1})),
        optionCutter: {
          bounding: '600px 950px',
          cropSize: '100px 200px',
          cropStart: '30px 0px',
          src: "",
          imageload: ''
        },
        regions: {
          "laf.header": {
            "x": 50,
            "y": 50,
            "width": 50,
            "height": 50,
            "scaleX": 1,
            "scaleY": 1
          }
        },
        imageload: false,
        optionImageThub: null,
        showLoader: true,
        preHtml: '',
        remotePic: '',
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
        },
        templateCurrent: null,
        templateJson: {
          laf: {
            marker: {
              text: "laf",
              x: 103,
              y: 129,
              width: 900,
              height: 100
            },
            header: [
              {
                name: "kode",
                region_types: "image",
                page_number: 1,
                items: [
                  {
                    name: "barcode_laf",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  }
                ],
                rules: {},
                region: {
                  name: "header",
                  x: 0,
                  y: 0,
                  width: 1200,
                  height: 200
                }
              },
            ],
            data: [],
            footer: [
            ]
          }
        },
        cropperPosition: {
          x: 0,
          y: 0,
          width: 600,
          height: 200
        },
      };
    },
    handleCropMove() {
      this.getData();
    },
    onCropperReady(cropper) {
      this.$refs.cropper.setCropBoxData({left: 100, top: 50, width: 200, height: 100});
    },
    preview(data) {
      this.preHtml = data.html
    },
    imgLoad(params) {
      this.optionCutter.imageload = params
    },
    paramData() {
      this.mappingTemplate();
      return {
        ti_id: this.tID,
        data_json: this.templateJson,
        pattern: this.templateCurrent.all.pattern,
        data_settings: this.templateCurrent,
        status: "ready",
      };
    },
    postData() {

      this.$http
          .put(`/api/v1/ocr/laf/templates/file/${this.tID}`, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code === 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id', this.tID)
      form.append('file', this.file)
      return form
    },
    paramImageS3(currPage) {
      var form = new FormData()
      form.append('pdf_name', this.fileName)
      form.append('page', currPage)
      return form
    },
    paramImage64(image64, text_type) {
      var form = new FormData()
      form.append('image64', image64)
      form.append('text_type', text_type)
      return form
    },
    getTextOcr(image64, varData) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/image64/v2", this.paramImage64(image64, "TEXT"), null)
          .then((resp) => {
            this.ocr_value = resp.data;
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getTextQR(image64) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/qr64", this.paramImage64(image64, "TEXT"), null)
          .then((respose) => {
            this.dataAll.qr_link = respose.data;
            if (this.dataAll.qr_link) {
              let respLaf = JSON.parse(respose.raw);
              console.log(respLaf);

              // this.getQRData(this.dataAll.qr_link);
              if (respLaf.resValidateLafPm) {
                let resp = respLaf.resValidateLafPm;
                this.dataAll.data_qr = resp;
                this.dataTemplate.barcode_laf = resp.nomorLaf;
                // "dndate":"2023-07-05","dph":"","dpp":""
                this.$forceUpdate();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "success get qr data",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: "Failed to get QR data",
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get QR data",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$forceUpdate()
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getPdfS3Image(pages) {
      pages = "all"
      if (this.fileName === null) {
        return
      }
      this.imageload = false;
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/pdf-s3/image64/v3", this.paramImageS3(pages), null)
          .then((resp) => {
            this.imageload = true;
            if (resp.data) {
              this.optionImageThub = resp.data;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (this.selectedImageIndex === -1) {
              this.selectedImageIndex = 0;
              this.previewImage(this.selectedImageIndex,"laf.header");
            }
            this.$vs.loading.close()
            // this.optionCutter.src = `data:image/png;base64,${resp}`
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    handleReplaceTemplate() {
      window.scrollTo(0, 0);
      const templateData = {
        "id": this.dataTemplate.id
      };
      this.$emit("close", templateData);
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    mappingTemplate() {
      this.templateJson = {
        laf: {
          marker: {
            text: "laf",
            x: 103,
            y: 129,
            width: 900,
            height: 100
          },
          header: [
            {
              name: "kode",
              region_types: "image",
              page_number: 1,
              items: [
                {
                  name: "barcode_laf",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.laf.barcode_laf.start,
                    end: this.templateCurrent.laf.barcode_laf.end,
                    delimiter: this.templateCurrent.laf.barcode_laf.delimiter,
                  },
                  types: "TEXT"
                }
              ],
              rules: {},
              region: {
                  name: "header",
                  x: this.regions["laf.header"].x,
                  y: this.regions["laf.header"].y,
                  width: this.regions["laf.header"].width,
                  height: this.regions["laf.header"].height
              }
            },
          ],
          data: [],
          footer: []
        }
      };
      this.templateCurrent["laf"]["header"]["region"] = this.regions["laf.header"];
      this.templateCurrent["laf"]["header"]["width"] = this.regions["laf.header"].width
      this.templateCurrent["laf"]["header"]["x"] = this.regions["laf.header"].x
      this.templateCurrent["laf"]["header"]["y"] = this.regions["laf.header"].y
    },
    handleUpdate() {
      this.status_submit = true;
      if (this.templateCurrent.all.pattern === null || this.templateCurrent.all.pattern === "" || this.templateCurrent.all.pattern === "undefined") {

        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Check required field",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
        }
      });
    },
    getBarcode() {
      this.$forceUpdate();
    },
    ensurePathExists(obj, path) {
      const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.'); // Mengubah [index] menjadi .index dan membagi path berdasarkan titik
      keys.reduce((acc, key, index) => {
        if (acc[key] === undefined || acc[key] === null) {
          acc[key] = isNaN(parseInt(keys[index + 1])) ? {} : [];
        }
        return acc[key];
      }, obj);
    },
    setDefault(obj, path, defaultValue) {
      this.ensurePathExists(obj, path);
      const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');
      const lastKey = keys.pop();
      const target = keys.reduce((acc, key) => acc[key], obj);
      if (target[lastKey] === undefined || target[lastKey] === null) {
        target[lastKey] = defaultValue;
      }
    }


  },
  beforeDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
  mounted() {

    this.$vs.loading();
    setTimeout(async () => {
      if (this.tID && this.dataTemplate) {
        this.pageCount = this.dataTemplate.page_count
        this.fileName = this.dataTemplate.file_name;

        // if (this.dataTemplate.settings.length > 4) {
        //     this.templateCurrent = JSON.parse(this.dataTemplate.settings);
        // } else
        {
          if (this.dataTemplate.template_json.length > 5) {
            const dataTemplate = JSON.parse(this.dataTemplate.template_json);
            const regionDefault = {
              name: "",
              x: 0,
              y: 0,
              width: 1200,
              height: 1700
            };

            const rulesDefault = {
              method: "GetTextBetween",
              params: {
                start: "",
                end: ""
              },
              types: "TEXT"
            };

// Mengatur default untuk laf footer
            this.setDefault(dataTemplate, 'laf.footer[0].region', {...regionDefault, name: "footer-1"});
            this.setDefault(dataTemplate, 'laf.footer[0].rules', rulesDefault);

// Mengatur default untuk laf header
            this.setDefault(dataTemplate, 'laf.header[0].region', {...regionDefault, name: "header-2"});
            this.setDefault(dataTemplate, 'laf.header[0].rules', rulesDefault);

// Memastikan params ada dan tidak undefined
            this.setDefault(dataTemplate, 'laf.header[0].rules.params', {start: "", end: ""});
            this.templateCurrent = {
              "all": {
                "pattern": this.dataTemplate.pattern
              },
              "laf": {
                "footer": {
                },
                "header": {
                  "page_number": 1,
                  "region_types": "image",
                  "width": dataTemplate.laf.header[0]["region"]["width"],
                  "x": dataTemplate.laf.header[0]["region"]["x"],
                  "y": dataTemplate.laf.header[0]["region"]["y"],
                  "region": dataTemplate.laf.header[0]["region"],
                },
                "barcode_laf": {
                  "start": dataTemplate.laf.header[0].items[0].params.start,
                  "end": dataTemplate.laf.header[0].items[0].params.end,
                  "delimiter": dataTemplate.laf.header[0].items[0].params.delimiter
                }
              }
            }
          } else {
            this.templateCurrent = {laf: {}, all: {pattern: ""}};
            this.setTemplateFields("laf", [
              {name: "header", lines: 5, page_number: 5, region_types: "image"},
              {name: "footer", lines: 5, page_number: 5, region_types: "text"},
              {name: "barcode_laf"},
            ]);
            this.setTemplateEmptyValue();
          }
        }


        const createRegion = (template, section) => ({
          x: template[section].x,
          y: template[section].y,
          width: template[section].width,
          height: template[section].height,
          scaleX: 1,
          scaleY: 1,
        });

        this.regions = {
          "laf.header": createRegion(this.templateCurrent.laf, "header"),
          "laf.footer": createRegion(this.templateCurrent.laf, "footer")
        };
        this.$forceUpdate()
        let pages = Array.from({length: this.pageCount + 2}, (_, i) => i + 1).join(', ');
        await this.getPdfS3Image(pages)
      }
    }, 2000)

  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
div.scroll-container {
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
}

div.scroll-container img {
  display: inline-block;
  margin-right: 10px;
}


.c img {
  margin: 0.3rem 0.5rem;
}

.cc button {
  margin: 1rem 0.5rem;
}

.showBox {
  width: 100%;
  background: #dedede;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 5px auto;
  min-height: 50px;
}

.imgCutDownBoxContainer {
  border: 1px solid #ccc;
  background: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;

}

.controller-icon-scale {
  padding: 0 8px;
  cursor: pointer;
  color: #c5cbd1;
}

.controller-icon-turn {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.icon {
  opacity: 0.5;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
}

.vc-controller:hover {
  background: rgba(255, 255, 255, 0.2);
}

.controller-icon {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}


.flex-container {
  display: flex;
}

.flex-item {
  padding: 10px;
}

.selected {
  border: 3px solid #968df3;
}


.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
