<template>
  <vx-card class="pt-5" :title="card.title+(card.subTitle?' | ' : '')+card.subTitle">
    <vs-tabs>
      <vs-tab @click="card.subTitle='AR Invoice'" label="AR Invoice">
        <div class="con-tab-ejemplo">
          <import_ar_invoice_xml />
        </div>
      </vs-tab>
      <vs-tab @click="card.subTitle='Debit Note'" label="Debit Note">
        <div class="con-tab-ejemplo">
          <import_dn_xml />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import import_ar_invoice_xml from './import-ar-invoice-xml.vue'
import import_dn_xml from './import-dn-xml.vue'

export default {
  components: {
    import_dn_xml,
    import_ar_invoice_xml
  },
  data(){
    return {
      card: {
        title: 'Import',
        subTitle: 'AR Invoice',
      }
    }
  }
}
</script>
