import { render, staticRenderFns } from "./form.vue?vue&type=template&id=a01e3186&scoped=true&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&id=a01e3186&prod&lang=css&"
import style1 from "./form.vue?vue&type=style&index=1&id=a01e3186&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a01e3186",
  null
  
)

export default component.exports