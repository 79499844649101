<template>
  <vx-card title="Journal">
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Operating Unit</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="operatingUnit.selected"
          :options="operatingUnit.options"
          :multiple="false"
          :allow-empty="false"
          :max-height="160"
          placeholder=" Type to search"
          track-by="TerritoryID"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.Name}} - {{ dt.option.TerritoryName }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{dt.option.Name}} - {{ dt.option.TerritoryName }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
            <span>Posting Date</span>&nbsp;
            <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('posting')"/>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
            <date-range-picker
                style="min-height: 40px"
                class="w-full"
                ref="picker"
                opens="center"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="true"
                v-model="filterPostingDate"
                :linkedCalendars="true"
            >
                <template v-slot:input="picker">
                    {{ datePickerDateFormat(picker.startDate) }} -
                    {{ datePickerDateFormat(picker.endDate) }}
                </template>
            </date-range-picker>
        </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Journal Name</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="processDocument.selected"
          :options="processDocument.options"
          :multiple="true"
          :allow-empty="true"
          :max-height="160"
          placeholder="All"
          track-by="Name"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.Name}}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{dt.option.Name}}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card>
            <vs-tabs :color="colorx">
                <vs-tab @click="colorx = 'success'" label="Failed">
                    <div class="con-tab-ejemplo">
                    <failed :operatingUnit="operatingUnit" :processDocument="processDocument" :postingDate="filterPostingDate"></failed>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Sync">
                    <sync :operatingUnit="operatingUnit" :processDocument="processDocument" :postingDate="filterPostingDate"></sync>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Success">
                    <success :operatingUnit="operatingUnit" :processDocument="processDocument" :postingDate="filterPostingDate"></success>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="All">
                    <all :operatingUnit="operatingUnit" :processDocument="processDocument" :postingDate="filterPostingDate"></all>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Export">
                    <download></download>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
  </vx-card>
</template>

<script>
import failed from "./failed.vue";
import sync from "./sync.vue";
import success from "./success.vue";
import all from "./all.vue";
import download from "./export.vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    components: {
        'failed': failed,
        'sync': sync,
        'success': success,
        'all': all,
        'download': download,
        DateRangePicker,
    },
    data: () => ({
        colorx: "success",
        operatingUnit : {
          selected: null,
          options: [],
        },
        processDocument : {
          selected: {ID: 0, Name: "All"},
          options: [],
        },
        filterPostingDate: {
            startDate: null,
            endDate: null,
        },
    }),
    mounted() {
        this.getOperatingUnit();
        this.getProcessDocument();

        this.filterPostingDate.startDate = null;
        this.filterPostingDate.endDate = null;
    },
    methods: {
        getOperatingUnit() {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/operating-unit-with-territory", {
            params: {
                order: "name",
                sort: "asc",
            },
            })
            .then((resp) => {
            if (resp.status == "success") {
                var options = [];
                options.push({
                ID: 0,
                Name: "All"
                });
                resp.data.records.forEach(function(item) {
                options.push(item);
                }, options);
                this.operatingUnit.options = options;
                this.operatingUnit.selected = this.operatingUnit.options[0];
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
          });
        },
        getProcessDocument() {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/process-document", {
            params: {
                order: "name",
                sort: "asc",
            },
            })
            .then((resp) => {
            if (resp.status == "success") {
                var options = [];
                resp.data.records.forEach(function(item) {
                options.push(item);
                }, options);
                this.processDocument.options = options;
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
          });
        },
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
        resetDateFilter(name) {
            if(name=="date") {
                this.filterDate.startDate = null;
                this.filterDate.endDate = null;
            } else if(name=="posting") {
                this.filterPostingDate.startDate = null;
                this.filterPostingDate.endDate = null;
            } else if(name=="openkeydate") {
                this.openKeyDate = null;
            }
        },
    },
};
</script>
 
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>