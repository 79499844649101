<template>
  <div class="vx-row" style="margin-top: 10px">
    <div class="vx-col md:w-1/1 w-full">
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formCompany.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Operating Unit</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formOU.inputs" @handlerSearch="handlerSearchMS"
                      @update-forminput="this.updateFormInput" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTerritory.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Invoice Period</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formMonth.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formYear.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Type</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTaxInvoiceType.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Posting Date</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs v-if="render" :components="this.formPostingDate.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Invoice Number</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formFromCode.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formToCode.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-4/5 w-full text-center">
          <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
          </vs-button>
          <vs-button class="mr-4" color="danger" icon-pack="feather" type="border" @click="$refs.file.click()">Import
          </vs-button>
          <input type="file" ref="file" @change="handleImport" style="display: none;"/>
        </div>
      </div>
      <vs-divider class="mb-2"/>
      <vs-tabs v-model="tabs">
        <vs-tab label="List of Invoices">
          <div class="con-tab-ejemplo">
            <datatable :itemRefs="this.itemRefs" :tablep="this.table" :modelItemRefInput="this.tmpItemRef"
                       :hideSearch="true"  />
          </div>
        </vs-tab>
        <vs-tab label="History"></vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import formInputs from "../form_inputs.vue";
import dataTable from "../datatable.vue";
import moment from "moment/moment";
import datatable from "@/views/oms/master/tax-invoice-xml/datatable.vue";

export default {
  components: {
    datatable,
    formInputs,
    dataTable
  },
  mounted() {
    this.getData()
    this.getTaxType()
  },
  data(){
    const baseUrl = window.location.origin
    return {
      render: true,
      tabs: 0,
      id_inv: [],
      itemRefs: [],
      tmpItemRef: {
        action: false,
        inputs: this.getInputsHead(),
      },
      table: {
        start: 0,
        end: 0,
        page: 0,
        stripe: false,
        length: 10,
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
      },
      invoiceCodes: [],
      formCompany: {
        id: 1,
        inputs: {
          disabled: true,
          validate: "required",
          type: "input",
          name: "company",
          value: ""
        }
      },
      formOU:{
        id: 2,
        inputs: {
          disabled: false,
          name: "operating_unit",
          validate: "required",
          type: "multiselect",
          value: {},
          option: [],
          allowEmpty: false,
          multiple: false,
          track_by: "ID",
        }
      },
      formTerritory: {
        id: 3,
        inputs:{
          disabled: false,
          validate: "required",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowEmpty: false,
          track_by: "ID",
        },
      },
      formMonth: {
        id: 4,
        inputs: this.formInputMonth(),
      },
      formYear: {
        id: 5,
        inputs: this.formInputYear(),
      },
      formTaxInvoiceType: {
        id: 6,
        inputs: {
          disabled: false,
          validate: "required",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowEmpty: false,
          track_by: "ID",
        }
      },
      formPostingDate: {
        id: 7,
        inputs: this.formInputPostingDate(),
      },
      formFromCode: {
        id: 8,
        inputs: {
          disabled: false,
          validate: "required",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowEmpty: false,
          track_by: "ID",
        }
      },
      formToCode: {
        id: 9,
        inputs: {
          disabled: false,
          validate: "required",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowEmpty: false,
          track_by: "ID",
        }
      },
      formInvoiceType: {
        id: 10,
        inputs: {
          disabled: false,
          validate: "required",
          name: "invoice_type",
          type: "multiselect",
          value: {
            id: 0,
            text: "AR Invoice",
          },
        }
      },
      dataTerritory: [],
      dataOperatingUnit: []

    }
  },
  watch:{
    "formTerritory.inputs.value": function () {
      this.getInvoiceCode(this.formPostingDate.inputs.value)
    },
  },
  methods: {
    getInputsHead() {
      const inputs = []

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Number",
          title: "Tax Number",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Type",
          title: "Tax Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "client_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "client_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    handlerSearchMS(comp, search) {
      if (comp.name === "operating_unit") {
        this.formOU.inputs.option = this.tempDataOu.filter(v => {
          const tempText = v.text.toLowerCase()
          const tempSearch = search.toLowerCase()
          return tempText.search(tempSearch) >= 0
        })
      }
    },
    updateFormInput(selected, component) {
      if (component.name === "operating_unit") {
        console.log("updateFormInput")
        this.getDataTerritory(selected);
      }
    },
    formInputPostingDate(){
      const today = new Date();

      return {
        disabled: false,
        validate: "required",
        type: "dateRange",
        value: {
          startDate: new Date(today.getFullYear(), today.getMonth(), 1),
          endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
        },
        format: 'yyyy-MM-dd',
        multiple: false,
        allowEmpty: false,
      }
    },
    formInputMonth(){
      return {
        disabled: false,
        type: "date",
        min_view: "month",
        format: "MM",
        placeholder: "Select Month",
        value: new Date(),
        multiple: false,
        allowEmpty: false,
      }
    },
    formInputYear(){
      return {
        disabled: false,
        type: "date",
        min_view: "year",
        format: "yyyy",
        placeholder: "Select Year",
        value: new Date(),
        multiple: false,
        allowEmpty: false,
      }
    },
    getData(){
      this.$vs.loading();
      const params = {
        load_operating_unit: true,
      }

      this.$http
        .get(`api/v1/master/tax-invoice`, { params })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code === 200) {
            this.formCompany.inputs.value = resp.data.company;

            const dataOU = resp.data.dtOu;
            this.DataOperatingUnit = resp.data.dtOu;

            if (dataOU) {
              let dataSelect = [];
              dataOU.forEach((dt, i) => {
                dataSelect.push({
                  ID: i,
                  text: dt.Code + " - " + dt.Name,
                });
              });
              this.formOU.inputs.value = dataSelect[0];

              this.getDataTerritory(this.formOU.inputs.value);
              this.tempDataOu = dataSelect
              this.formOU.inputs.option = dataSelect;
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    getDataTerritory(selected = null) {
      this.$vs.loading();
      if (selected) {
        const params = {
          id_ou: this.DataOperatingUnit[selected.ID].ID,
        };
        this.$http
          .get(`api/v1/master/tax-invoice/territory`, { params })
          .then((resp) => {
            this.formTerritory.inputs.value = null
            this.formTerritory.inputs.option = []
            this.formFromCode.inputs.value = null
            this.formToCode.inputs.value = null
            this.$vs.loading.close();
            if (resp.code === 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code === 200) {
              var dataSelect = [];
              if (resp.data.dataTerr.length) {
                this.dataTerritory = resp.data.dataTerr;
                this.dataTerritory.forEach((dt, i) => {
                  dataSelect.push({
                    ID: i,
                    text: dt.Code + " - " + dt.Name,
                  });
                });
              }
              if (dataSelect.length) {
                this.formTerritory.inputs.value = dataSelect[0];
                this.formTerritory.inputs.option = dataSelect;
                this.getInvoiceCode(this.formPostingDate.inputs.value)
              }
            }
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    getTaxType() {
      this.$http
        .get(`api/v1/master/tax-type`)
        .then((resp) => {
          if (resp.code === 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code === 200) {
            resp.data.records.sort((a, b) => a.code - b.code)
            this.formTaxInvoiceType.inputs.option = [{ ID: 0, text: "ALL", code: "ALL" }, ...resp.data.records.map((t) => ({ ID: t.ID, text: `${t.code} ${t.name}`, code: t.code }))];
            this.formTaxInvoiceType.inputs.value = { ID: 0, text: "ALL", code: "ALL" }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    async handleImport(file) {
      try {
        console.log(file);
        this.$vs.loading();
        const formData = new FormData()
        formData.append("file", file.target.files[0])
        console.log(formData);
        const response = await this.$http.post(`api/v1/master/tax-invoice/import-tax-invoice`, formData)

        if (response.code !== 200) {
          throw new Error(response.message);
        }

        this.$vs.dialog({
          type: "alert",
          color: "success",
          title: `Success`,
          text: response.message,
          acceptText: "Confirm",
        });
      } catch (error) {
        this.$vs.dialog({
          type: "alert",
          color: "danger",
          title: `Error`,
          text: error,
          acceptText: "Confirm",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async getInvoiceCode(selected){
      try {

        const startDate = moment(selected.startDate).format("yyyy-MM-DD")
        const endDate = moment(selected.endDate).format("yyyy-MM-DD")

        const territory = this.dataTerritory[this.formTerritory.inputs.value.ID]

        if (!territory) {
          return
        }

        const params = {
          territory_id: territory.TerrID,
          start_date: startDate,
          end_date: endDate,
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
          tax_type_code: this.formTaxInvoiceType.inputs.value.code,
          is_generate: true,
          is_imported: true,
        }

        this.$vs.loading();

        const response = await this.$http.get(`api/v1/master/tax-invoice/invoice-code-core-tax`, { params });

        if (response.code !== 200) {
          this.throwError(response.message)
        }

        this.invoiceCodes = response.data;
        const first = response.data[0];
        const last = response.data[response.data.length - 1]

        if (first) {
          this.formFromCode.inputs.value = { id: first.id, text: first.code }
        } else {
          this.formFromCode.inputs.value = null
        }
        this.formFromCode.inputs.option = response.data.map(d => ({ id: d.id, text: d.code }))
        if (first) {
          this.formToCode.inputs.value = { id: last.id, text: last.code }
        } else {
          this.formToCode.inputs.value = null
        }
        this.formToCode.inputs.option = response.data.map(d => ({ id: d.id, text: d.code }))
      } catch (error) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    throwError(message) {
      throw new Error(message);
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total === 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search !== ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getInputs(data = null, i = 0, no = 1) {
      const inputs = [];
      let period = "";
      let invoice_no = "";
      let customer_code = "";
      let customer_name = "";
      let tax_invoice_type = "";
      let tax_number = ""
      let temp_kode_dokumen_pendukung = ""
      let custRoute = ""
      let invRoute = ""
      if (data) {
        period = data.PostingDate;
        if (period) {
          period = moment(period).format("YYYY-MM-DD");
        }
        invoice_no = data.Code;
        customer_code = data.CustomerCode;
        customer_name = data.CustomerName;
        tax_number = data.TaxNumber
        temp_kode_dokumen_pendukung = data.KodeDokumenPendukung
        custRoute = this.$router.resolve({
          name: 'customers-edit',
          params: { id: data.CustomerID },
        })
        custRoute = custRoute.href
        tax_invoice_type = data.TaxType;
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "text",
          value: period,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Invoice No",
          title: "Invoice No",
          target: "_blank",
          href: invRoute,
          type: "text-link",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Number",
          title: "Tax Number",
          type: "text",
          value: tax_number,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "client_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          target: "_blank",
          type: "text-link",
          href: custRoute,
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "client_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "text",
          value: customer_name,
        },
      });

      return inputs;
    },
    handleView(){
      const tabs = this.tabs
      switch(tabs) {
        case 0:
          this.getDataInvoice()
          break
        default:
          break
      }
    },
    async getDataInvoice() {
      try {
        const startDate = moment(this.formPostingDate.inputs.value.startDate).format("yyyy-MM-DD")
        const endDate = moment(this.formPostingDate.inputs.value.endDate).format("yyyy-MM-DD")
        const startID = this.formFromCode.inputs.value ? this.formFromCode.inputs.value.id : 0
        const endID = this.formToCode.inputs.value ? this.formToCode.inputs.value.id : 0
        const month = this.formMonth.inputs.value ? this.formMonth.inputs.value.getMonth() : null
        const year = this.formYear.inputs.value ? this.formYear.inputs.value.getFullYear() : null

        if (!this.formToCode.inputs.option.length || !this.formFromCode.inputs.option) {
          this.throwError("Invoice Code is empty")
        }

        if ((month === null || month === undefined)|| !year)  {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Fill the period first",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false
        }
        if (!(startID && endID)) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Fill the Invoice Number First",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false
        }

        if (this.dataTerritory[this.formTerritory.inputs.value.ID]) {
          this.$vs.loading()
          const params = {
            length: this.table.length,
            page: this.table.page,
            order: this.table.order,
            sort: this.table.sort,
            invoice_type: this.formInvoiceType.inputs.value.text,
            territory_id:
            this.dataTerritory[this.formTerritory.inputs.value.ID].TerrID,
            start_date: startDate,
            end_date: endDate,
            start_id: startID,
            end_id: endID,
            tax_type_code: this.formTaxInvoiceType.inputs.value.code,
            is_core_tax: true,
            is_imported: true,
          };


          const response = await this.$http(`api/v1/master/tax-invoice/data-exported-core-tax`, { params });

          if (response.code !== 200) {
            this.throwError(response.message)
          }

          const dataInvoice = [];
          this.table.total = response.data.total_record
          this.table.totalPage = response.data.total_page;
          this.table.totalSearch = response.data.total_record_search;
          this.table.length = response.data.total_record_per_page;
          this.setStartEnd()

          let no = this.table.start
          response.data.dataInvoice.forEach((dt, i) => {
            dataInvoice.push({
              id: i,
              id_invoice: dt.ID,
              action: false,
              inputs: this.getInputs(dt, i, no),
            });
            no++
          });

          this.itemRefs = dataInvoice;
        }
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>
