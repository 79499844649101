<template>
  <div class="flex flex-col gap-8 mt-4">
    <div class="flex gap-3">
      <vs-button v-on:click="handleRelease" icon="done" color="primary">
        Send Released
      </vs-button>
      <vs-button v-on:click="createCreditNoteClaim" color="primary">
        Create Credit Note Claim
      </vs-button>
      <vs-button v-on:click="createCreditNoteManual" color="primary">
        Create Credit Note Claim Mix
      </vs-button>
    </div>

    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <vs-th>
              <vs-checkbox
                color="success"
                v-on:click="addAllValidate()"
                v-model="validateAll"
              >
                Check All
              </vs-checkbox>
            </vs-th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.creditNote"
            >
              <vs-td>
                <vs-checkbox
                  color="success"
                  :checked="validate.includes(tr.ID)"
                  v-on:click="addValidate(tr.ID)"
                >
                </vs-checkbox>
              </vs-td>
              <vs-td>
                <template>
                  <!-- <vx-tooltip text="View Credit Note">
                    <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      v-on:click="handleView(tr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip> -->
                  <vx-tooltip
                    v-if="tr.SalesReturnCode == ''"
                    text="Edit Credit Note"
                    style="width: fit-content"
                  >
                    <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      v-on:click="edit(tr)"
                      icon="icon-edit"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Delete" style="width: fit-content">
                    <vs-button
                      color="danger"
                      type="line"
                      icon-pack="feather"
                      v-on:click="handleDelete(tr)"
                      icon="icon-x-square"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                Credit Note Code : {{ tr.Code }}<br />
                Debit Note Code :
                {{ getDebitNote(tr.DebitNoteID, tr.CnReferenceCode) }}<br />
                <span v-if="tr.SalesReturnCode != ''"
                  >Customer Return Code :
                  {{ tr.SalesReturnCode == "" ? "-" : tr.SalesReturnCode }}<br
                /></span>
                Type : {{ getSalesReturnType(tr.SalesReturnID) }}
                <template v-if="tr.SalesReturnCode == ''">
                  {{ generateType(tr.Type) }}
                </template>
              </vs-td>
              <vs-td>
                Code : {{ tr.CustomerCode }}<br />
                Name : {{ tr.CustomerName }}<br />
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.PostingDate) }}
              </vs-td>
              <vs-td>
                <span>
                  {{ tr.Type === "Manual" ? "Reference : " : "Note : " }}
                  {{ tr.Note ? tr.Note : "-" }}</span
                >
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail(true)"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <template v-if="create == false">
              <template v-if="selectedData.Type == 'Manual Claim'">
                <view-manual :selected="selectedData" />
              </template>
              <template v-else>
                <form-view :selected="selectedData" />
              </template>
            </template>

            <create
              @closeDetail="closeDetail"
              :selected="selectedData"
            ></create>
            <!-- <credit-note-form v-else /> -->
          </div>
        </div>
      </transition>
    </div>
    <vs-popup
      title="Codes"
      button-close-hidden
      :active.sync="invoicePopup"
      :closeOnBackdrop="false"
      ref="popup"
    >
      <div class="w-full flex flex-col items-end gap-2">
        <div
          v-if="createdInvoiceCodes && isShowPopup(createdInvoiceCodes)"
          style="overflow-y: auto; max-height: 400px"
          class="w-full flex flex-col gap-3 p-3"
        >
          <vx-card
            class="flex flex-col gap-2 w-full"
            v-for="(data, i) in releaseResponse.data"
            :key="i.toString()"
          >
            <div class="flex flex-col gap-1 w-full">
              <span class="font-bold"
                >Credit Note Code : {{ data.credit_note_code }}</span
              >
            </div>

            <div class="flex flex-col gap-1 w-full">
              <span class="font-bold">PPh Invoice Codes</span>
              <div class="flex gap-3">
                <vs-input
                  v-model="createdInvoiceCodes[i]"
                  class="w-full"
                  readonly
                />
                <vx-tooltip text="Copy">
                  <vs-button
                    iconPack="feather"
                    icon="icon-file"
                    @click="copyToClipboard(createdInvoiceCodes[i])"
                    type="border"
                    :disabled="createdInvoiceCodes[i] === null"
                    >Copy</vs-button
                  >
                </vx-tooltip>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="mt-8">
          <vs-button color="danger" @click="closePopup">Close</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import formView from "./form_view.vue";
import create from "./create.vue";
import viewManual from "./view_manual.vue";
import creditNoteform from "./credit_note_form.vue";
import {
  dataTableCreditNote,
  multipleUpdateStatus,
  deleteCreditNote,
  releaseCreditNote,
} from "../../../../services/api/credit_note";
import moment from "moment";
import VxTooltip from "../../../../layouts/components/vx-tooltip/VxTooltip.vue";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
  },
  components: {
    "form-view": formView,
    "view-manual": viewManual,
    "credit-note-form": creditNoteform,
    create: create,
    VxTooltip,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "credit_notes.created_at",
      },

      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Code",
          value: "credit_notes.code",
        },
        {
          text: "Customer",
          value: "credit_notes.customer_name",
          // width: '5%'
        },
        {
          text: "Posting Date",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Info",
          sortable: false,
          // width: '5%'
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      validate: [],
      validateAll: false,
      salesReturn: [],
      salesReturnType: [],
      create: false,
      isCloseButton: false,
      createdInvoiceCodes: [],
      invoicePopup: false,
      releaseResponse: null,
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
    invoicePopup() {
      if (this.invoicePopup) {
        return;
      }

      if (!this.isCloseButton) {
        this.invoicePopup = true;
        return;
      }

      this.isCloseButton = false;
    },
  },
  mounted() {},
  methods: {
    dateFormat(date) {
      if (date) {
        return moment.utc(date).format("DD/MM/YYYY");
      }
    },
    getSalesReturnType(salesReturnID) {
      try {
        const salesReturn = this.salesReturn.filter((v) => {
          return v.ID == salesReturnID;
        })[0];
        console.log(salesReturn, salesReturnID);
        return this.salesReturnType.filter((v) => {
          return v.ID == salesReturn.SalesReturnTypeID;
        })[0].Name;
      } catch (err) {
        return "";
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    createManual() {
      this.selectedData = { ID: 0, customer_id: 0, amount: 0 };
      this.create = true;
      this.detail = true;
    },
    handleEdit(selected) {
      this.selectedData = selected;
      this.create = true;
      this.detail = true;
    },
    edit(selected) {
      this.selectedData = selected;

      if (this.selectedData.Type == "Manual") {
        this.$router.push({
          name: "edit-credit-note-manual",
          params: { ID: btoa(this.selectedData.ID) },
        });
      } else {
        this.$router.push({
          name: "edit-credit-note-claim",
          params: { ID: btoa(this.selectedData.ID) },
        });
      }
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    handleView(selected) {
      this.create = false;
      this.selectedData = selected;
      this.detail = true;
    },
    sendApprove() {
      console.log(this.validate);
      if (this.validate.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
        });
        return;
      }
      const params = {
        credit_note_id: this.validate,
        status: 3,
      };
      this.$vs.loading();
      const updateStatus = multipleUpdateStatus(params);
      updateStatus
        .then((r) => {
          console.log(r);
          if (r.code < 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Credit Note has been sent for approval",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: r.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        });
    },
    handleDelete(selected) {
      console.log(selected);
      this.$vs.loading();
      const deleteCN = deleteCreditNote(selected);
      deleteCN.then((r) => {
        this.reloadData(this.params);
        this.$vs.loading.close();
      });
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
        type_not_in: ["Temporary"],
      };
      this.$vs.loading();

      const salesReturn = dataTableCreditNote(this.params);
      salesReturn.then((r) => {
        if (r.code == 500) {
          this.$vs.lFreloadoading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.creditNote;
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          this.responseData.recordsTotal = r.data.recordsTotal;
          this.salesReturn = r.data.salesReturn;
          this.salesReturnType = r.data.salesReturnType;
          //   this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    createCreditNoteClaim() {
      this.$router.push({ name: "create-credit-note-claim" });
    },
    createCreditNoteManual() {
      this.$router.push({ name: "create-credit-note-manual" });
    },
    handleRelease() {
      console.log(this.validate);
      if (this.validate.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
        });
        return;
      }
      const params = {
        credit_note_id: this.validate,
        status: 4, //release
      };

      this.$vs.loading();
      releaseCreditNote(params)
        .then((r) => {
          console.log(r);
          if (r.code < 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Credit Note has been released",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
            });

            this.releaseResponse = r;
            if (
              this.releaseResponse.data &&
              this.releaseResponse.data.length > 0
            ) {
              this.createdInvoiceCodes = this.releaseResponse.data.map(
                (data) => {
                  if (data.invoices.length === 0) {
                    return null;
                  }
                  let codes = "";
                  for (let i = 0; i < data.invoices.length; i++) {
                    codes +=
                      data.invoices[i].code +
                      (i < data.invoices.length - 1 ? ", " : "");
                  }
                  return codes;
                }
              );
              this.invoicePopup = this.isShowPopup(this.createdInvoiceCodes);
              if (!this.invoicePopup) {
                this.reloadData(this.params);
              }
            } else {
              this.reloadData(this.params);
            }

            this.validate = [];
          } else {
            this.$vs.notify({
              title: "Error",
              text: r.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.log(e);
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
          });
        });
    },
    getDebitNote(id, ref) {
      let dn = this.responseData.debitNote.find((v) => v.ID === id);
      if (dn && Object.keys(dn).length > 0 && dn.ID !== 0) {
        return dn.Code ? dn.Code : "-";
      }

      if (ref === "") {
        return "-";
      }

      dn = this.responseData.debitNote.find((v) => v.DnReferenceCode === ref);
      if (dn && Object.keys(dn).length > 0 && dn.ID !== 0) {
        return dn.Code ? dn.Code : "-";
      }

      return "-";
    },

    async copyToClipboard(toCopy) {
      try {
        await navigator.clipboard.writeText(toCopy);
        this.$vs.notify({
          title: "Success",
          text: "Copied to clipboard",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to copy to clipboard",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },

    closePopup() {
      this.isCloseButton = true;
      this.invoicePopup = false;
      this.validate = [];
      this.reloadData(this.params);
    },

    generateType(type) {
      if (type === "Manual") {
        return "Claim Mix";
      } else {
        return type;
      }
    },

    isShowPopup(codes = []) {
      return codes.some((code) => code !== null);
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
